import React from 'react';
import { graphql, Link } from 'gatsby';
import { Layout, SEO, ContentSection } from '@egonoid/gatsby-theme-common';

// import styles from './page.module.scss';

interface IProps {
  pageContext: any;
  data: any;
  location: any;
}

const Categories: React.FC<IProps> = ({ pageContext, data, location }) => {
  const { category } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const categoryHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } are in category "${category}"`;

  return (
    <Layout location={location} title={categoryHeader} showLogo={true}>
      <SEO title={categoryHeader} />
      <ContentSection>
        <h1>{categoryHeader}</h1>
      </ContentSection>
      <ContentSection>
        <ul>
          {edges.map(({ node }) => {
            const { slug } = node.fields;
            const { title } = node.frontmatter;
            return (
              <li key={slug}>
                <Link to={slug}>{title}</Link>
              </li>
            );
          })}
        </ul>
        <Link to="/categories">All categories</Link>
      </ContentSection>
    </Layout>
  );
};

export default Categories;

export const query = graphql`
  query($category: String) {
    allMdx(
      limit: 2000
      filter: {
        frontmatter: { template: { eq: "post" }, category: { eq: $category } }
        fields: { isVisible: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
